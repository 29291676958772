import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const tagline = data.site.siteMetadata.tagline
    const posts = data.allMarkdownRemark.edges
    const menu = data.site.siteMetadata.menuLinks

    return (
      <Layout location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
        <SEO title="Everything is a work in progress" />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
           return (
            <article key={node.fields.slug}>
                <h3 
                  style={{
                    marginBottom: rhythm(1/4),
                    ...scale(1)
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <p 
                  style={{
                  marginBottom: rhythm(1/4),
                }}className="pub-details">/ {node.frontmatter.date}</p>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          )
         
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {isBlog: {eq: true}}},sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            isBlog
          }
        }
      }
    }
  }
`
